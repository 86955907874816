import React, { useState, useEffect } from "react";
import {
    Tabs
} from 'antd';
import {
    RightOutlined,
} from '@ant-design/icons';
import './index.css';
import { useLocation, useNavigate } from "react-router-dom";
import MyArtSpace from './myArtSpace';
import RechargeRecord from './rechargeRecord';
import homeicon from '../../images/homeicon.png';

const UserCenter = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [defaultActiveKey, setDefaultActiveKey] = useState('1');
    const onChange = (key) => {
        setDefaultActiveKey(key);
    };
    const items = [
        {
            key: '1',
            label: 'My Space',
            children: <MyArtSpace/>
        },
        {
            key: '2',
            label: 'Transaction',
            children: <RechargeRecord/>,
        }
    ];

    useEffect(() => {
        if (location?.state?.key === '2') {
            setDefaultActiveKey('2');
        } else {
            setDefaultActiveKey('1');
        }
    }, [location]);

    return <div className="user-center">
        <div className="bg"></div>
        <div className="user-header">
            <div className="user-center-header">
                <img src={homeicon} alt="" onClick={() => navigate(`/`)} />
                <div><RightOutlined/></div>
                <div>{defaultActiveKey === '1' ? "My Space" : "Transaction"}</div>
            </div>
        </div>
        <Tabs
            activeKey={defaultActiveKey}
            items={items}
            onChange={onChange}
            tabBarGutter={36}
        />
    </div>
}

export default UserCenter;