import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/home';
import AppList from '../pages/appList';
// import Pricing from '../pages/pricing';
// import ContactUs from '../pages/contactus';

// import StyleIdToImage from '../pages/styleIdToImage';
import StyleID from '../pages/styleID';
import FaceID from '../pages/faceID';
import DuoID from '../pages/duoID';

import UserCenter from '../pages/userCenter';
// import Privacy from '../pages/privacy';

import SigninCallback from '../auth/SigninCallback';
import SilentRenew from '../auth/SilentRenew';

export default function AppRouter() {

    return (
        <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route path="/app/:id" element={<AppList/>} />
            {/* <Route path="/pricing" element={<Pricing />} /> */}
            {/* <Route path="/contactus" element={<ContactUs />} /> */}
            <Route path="/myspace" element={<UserCenter />} />

            {/* <Route path="/styleId_to_image" element={<StyleIdToImage />}/> */}
            <Route path="/styleid" element={<StyleID />}/>
            <Route path="/faceid" element={<FaceID />}/>
            <Route path="/duo" element={<DuoID />}/>

            {/* <Route path="/privacy" element={<Privacy />} /> */}

            {/* this addr should not be signin-callback as that is used by react-oidc-content for callback*/}
            <Route path="/signinredir" element={<SigninCallback />} /> 
            <Route path="/silent-renew" element={<SilentRenew />} />
        </Routes>
    );
}