import React, { useRef, useState } from 'react';
import './index.css';
import { Button } from 'antd';
import {
    RightOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import generate_images_bg_1 from '../../../images/generate_images_bg_1.png';
import generate_images_bg_2 from '../../../images/generate_images_bg_2.png';
import generate_images_bg_3 from '../../../images/generate_images_bg_3.png';
import home_styleid_bg from '../../../images/home_styleid_bg.png';
import home_faceid_bg from '../../../images/home_faceid_bg.png';
import home_duo_bg from '../../../images/home_duo_bg.png';

const ContentFour = () => {
    const elementOneRef = useRef(null);
    const elementTwoRef = useRef(null);
    const elementThreeRef = useRef(null);
    const [isOneVisible,] = useState(false);
    const [isTwoVisible,] = useState(false);
    const [isThreeVisible,] = useState(false);
    const navigate = useNavigate();
  

    // //初始化从两边到中间移动动画
    // useEffect(() => {
    //     const observerOne = new IntersectionObserver(
    //         ([entry]) => {
    //             if (entry.isIntersecting) {
    //                 setIsOneVisible(true);
    //                 observerOne.disconnect(); // 一旦可见，停止观察
    //             }
    //         },
    //         { threshold: .5 } // 视口的 50% 进入时触发
    //     );
    //     const observerTwo = new IntersectionObserver(
    //         ([entry]) => {
    //             if (entry.isIntersecting) {
    //                 setIsTwoVisible(true);
    //                 observerTwo.disconnect(); // 一旦可见，停止观察
    //             }
    //         },
    //         { threshold: .5 } // 视口的 50% 进入时触发
    //     );
    //     const observerThree = new IntersectionObserver(
    //         ([entry]) => {
    //             if (entry.isIntersecting) {
    //                 setIsThreeVisible(true);
    //                 observerThree.disconnect(); // 一旦可见，停止观察
    //             }
    //         },
    //         { threshold: .5 } // 视口的 50% 进入时触发
    //     );
    
    //     elementOneRef.current && observerOne.observe(elementOneRef.current);
    //     elementTwoRef.current && observerTwo.observe(elementTwoRef.current);
    //     elementThreeRef.current && observerThree.observe(elementThreeRef.current);
  
    //     return () => {
    //         elementOneRef.current && observerOne.unobserve(elementOneRef.current); // 清理观察者
    //         elementTwoRef.current && observerTwo.unobserve(elementTwoRef.current); // 清理观察者
    //         elementThreeRef.current && observerThree.unobserve(elementThreeRef.current); // 清理观察者
    //     };
    // }, []);

    const onGenerateClick = (key) => {
        navigate(`/app/${key}`)
    };

    return (
        <div className='content-four'>
            <div className='content-four-one'>
                <div className='content-row' ref={elementOneRef}>
                    <div className={`content-row-img ${isOneVisible ? 'visible' : ''}`}>
                        <img src={home_styleid_bg} alt="" />
                    </div>
                    <div className={`content-row-text ${isOneVisible ? 'visible' : ''}`}>
                        <div className='text'>
                            <h2>{"StyleID"}</h2>
                            <div>
                                <p>{"Transform Your Look with Artistic Flair."}</p>
                                <p>
                                    {"Unleash your creativity with our unique avatar generator! \
                                    Choose from a variety of vibrant artistic styles to create a stunning, \
                                    personalized avatar that truly represents you. Whether you prefer bold \
                                    brush strokes or delicate illustrations, our tool allows you to express \
                                    your individuality like never before. Stand out on social media and make \
                                    a statement with a custom avatar that’s as unique as you are!"}
                                </p>
                            </div>
                        </div>
                        <Button
                            type="primary"
                            shape="round"
                            className='but'
                            onClick={() => onGenerateClick('StyleID')}
                        >{'Generate'}</Button>
                    </div>
                </div>
            </div>
            <div className='content-four-two'>
                <div className='content-row' ref={elementTwoRef}>
                    <div className={`content-row-text ${isTwoVisible ? 'visible' : ''}`}>
                        <div className='text'>
                            <h2>{"FaceID"}</h2>
                            <div>
                                <p>{"Create Realistic Avatar with a Personal Touch."}</p>
                                <p>
                                    {"Capture your essence with our realistic portrait generator. \
                                    This tool delivers lifelike avatars that maintain a subtle artistic \
                                    flair while emphasizing authenticity. Perfect for those who appreciate \
                                    detail and realism, you can customize features to reflect your unique \
                                    personality. Ideal for professional profiles or personal branding, \
                                    help you leave a lasting impression. "}
                                </p>
                            </div>
                        </div>
                        <Button
                            type="primary"
                            shape="round"
                            className='but'
                            onClick={() => onGenerateClick('FaceID')}
                        >{'Generate'}</Button>
                    </div>
                    <div className={`content-row-img ${isTwoVisible ? 'visible' : ''}`}>
                        <img src={home_faceid_bg} alt=""/>
                    </div>
                </div>
            </div>
            <div className='content-four-three'>
                <div className='content-row' ref={elementThreeRef}>
                    <div className={`content-row-img ${isThreeVisible ? 'visible' : ''}`}>
                        <img src={home_duo_bg} alt=""/>
                    </div>
                    <div className={`content-row-text ${isThreeVisible ? 'visible' : ''}`}>
                        <div className='text'>
                            <h2>{"Duo"}</h2>
                            <div>
                                <p>{"Capture Special Moments with Custom Duo Portraits."}</p>
                                <p>
                                    {"Celebrate life’s milestones with our duo portrait generator! \
                                    Effortlessly create stunning photos featuring you and a partner \
                                    in any chosen setting—be it a joyful celebration, a cherished memory, \
                                    or a casual moment. Our tool allows you to personalize your scene, \
                                    ensuring each snapshot uniquely reflects your bond. \
                                    Perfect for sharing on social media or preserving memories, \
                                    transform your special moments into beautiful, lasting keepsakes today!"}
                                </p>
                            </div>
                        </div>
                        <Button
                            type="primary"
                            shape="round"
                            className='but'
                            onClick={() => onGenerateClick('Duo')}
                            disabled
                        >{'Coming soon'}</Button>
                    </div>
                </div>
            </div>
            <div className='content-four-four'>
                <div className='content-row'>
                    <div className='title'>{"How it works ?"}</div>
                    <div className='alter'>{"Choose a template and upload your photo to generate exciting images"}</div>
                    <div className='content'>
                        {/* <div className='introduce'>
                            <div>{'Generate images'}</div>
                            <div>{'Bring your ideas to life一 generate powerful images with just a picture, instantly'}</div>
                        </div> */}
                        <div className='image-box'>
                            <div className='box'>
                                <img src={generate_images_bg_1} alt="" />
                                {/* <div>{'Choose the mode forStyleID, FaceID, or Duo'}</div> */}
                                <div>{"Select Category"}</div>
                            </div>
                            <div className='icon'>
                                <RightOutlined />
                            </div>
                            <div className='box'>
                                <img src={generate_images_bg_2} alt="" />
                                {/* <div>{'Select a theme and click Generate'}</div> */}
                                <div>{'Select themes'}</div>
                            </div>
                            <div className='icon'>
                                <RightOutlined />
                            </div>
                            <div className='box'>
                                <img src={generate_images_bg_3} alt="" />
                                {/* <div>{'Upload your protrait, click Generate to get exciting images'}</div> */}
                                <div>{'Upload photo to generate'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContentFour;