import React from "react";
import './index.css';
import loading_img2 from '../../images/loading_img2.png';

const PageLoading = () => {
    return <div className="page-loading">
        <div className='loading-box'>
            <img src={loading_img2} alt="" />
            <div>{"Loading..."}</div>
        </div>
    </div>
}
export default PageLoading;