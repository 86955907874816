import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import './index.css';
import img_404 from '../../images/img_404.png';

const ErrorFallback = ({ resetErrorBoundary }) => (
    <div role="alert" className='error'>
        <div className='error-box'>
            <img src={img_404} alt="" />
            <div>{'No Internet connection'}</div>
            {/* <p>{error.message}</p> */}
            <button onClick={resetErrorBoundary}>{"Refresh"}</button>
        </div>
    </div>
);

const ErrorBoundaryComponent = ({ children }) => (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
        {children}
    </ErrorBoundary>
);

export default ErrorBoundaryComponent;