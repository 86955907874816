import React, { useState, useEffect, useRef } from "react";
import './index.css';
import {
    Button,
    Form,
    message,
    Upload,
    Image,
    Modal,
    Skeleton,
    Carousel
} from 'antd';
import {
    RightOutlined,
    PlusOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import {
    StyleIdAPI,
    GetScenesInfo,
    InfoByTaskID
} from '../../api/request.js';
import 'react-resizable/css/styles.css';
import homeicon from '../../images/homeicon.png';
import noimage from '../../images/noimage.png';
import integral_icon from '../../images/integral_icon.png';
import action_loading_error from '../../images/action_loading_error.png';
import notice_modal_image from '../../images/notice_modal_image.webp';
// import template4 from '../../images/template4.png';
// import template5 from '../../images/template5.png';
// import template6 from '../../images/template6.png';
// import template7 from '../../images/template7.png';
import { useTasks, useTasksDispatch } from '../../TasksContext';
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from 'react-oidc-context';
import useCatchAsyncError from '../../hooks/useCatchAsyncError.jsx';
import { getdataIDB, deletedataIDB, updataIDB } from '../../api/idb.js';

const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}
function dataURLtoBlob(dataurl) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new Blob([u8arr], {type: mime});
}

const StyleID = (props) => {
    const { config } = props;
    const auth = useAuth();
    const tasks = useTasks();
    const dispatch = useTasksDispatch();
    const myRef = useRef(null);
    const styleIdInterval = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const { type, name } = location.state || {};
    const setError = useCatchAsyncError();

    const [isInside, setIsInside] = useState(false);
    const [isMoving, setIsMoving] = useState(false);

    const [renderData, setRenderData] = useState({});
    const [carouselData, setCarouselData] = useState([]);
    const [templateImage, setTemplateImage] = useState(1);
    const [form] = Form.useForm();
    const [getImageUrl, setGetImageUrl] = useState([]);
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [generateImagePreview, setGenerateImagePreview] = useState(0);
    const [submitError, setSubmitError] = useState(false);
    const [initialization, setInitialization] = useState(true);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [allowUpload, setAllowUpload] = useState(false);

    const [noticeModalOpen, setNoticeModalOpen] = useState(false);

    const smoothScrollTo = (element) => {
        const top = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo(0, top);
    };
    const handleSubmit = () => {
        if (auth?.isAuthenticated) {
            if (window.innerWidth < 768) {
                if (myRef.current) {
                    smoothScrollTo(myRef.current)
                }
            }
            form.submit();
        } else {
            const currentUrl = `/app/StyleID`;
            sessionStorage.setItem('redirectUrl', currentUrl);
            if (tasks[6].isEver) {
                auth.signinRedirect();
            } else {
                dispatch({
                    type: 'set_login_intercept_modal',
                    data: {
                        isModal: true,
                        isEver: tasks[6].isEver,
                        currentUrl: currentUrl
                    }
                });
            }
        }
    };
    const handleFileChange = (template) => {
        setTemplateImage(template);
    };
    const infoByTaskID = async ({taskid, uid, token}) => {
        const res = await InfoByTaskID({ taskid, uid, token });
        if (res.data?.data?.url) {
            setGetImageUrl(res.data.data.url);
            dispatch({
                type: 'set_scene_gen_status',
                value: 3,
            });
            clearInterval(styleIdInterval.current);
            setDisabledSubmit(false);
        } else if (res.data?.code !== 1000) {
            clearInterval(styleIdInterval.current);
            message.error('Please refresh and try again later!');
            setSubmitError(true);
            setDisabledSubmit(false);
        }
    }
    const handleFormSubmit = async (values) => {
        setSubmitError(false);
        if (initialization) {
            setInitialization(false);
        }
        if (auth.isAuthenticated) {
            setDisabledSubmit(true);
            try {
                setGetImageUrl('');
                dispatch({
                    type: 'set_scene_gen_status',
                    value: 1,
                });
                const formData = new FormData();
                for (let i = 0; i < values.source.length; i++) {
                    formData.append('sourceImage', values.source[i].originFileObj);
                }
                formData.append('type', templateImage);
                formData.append('category', renderData.category);
                formData.append('uid', auth.user.profile.sub);

                const res = await StyleIdAPI({
                    formData: formData,
                    token: auth.user.access_token,
                });
                if (res?.data?.code === 1000) {
                    styleIdInterval.current = setInterval(() => {
                        infoByTaskID({ taskid: res.data.data.taskid, uid: auth.user.profile.sub, token: auth.user.access_token });
                    }, 5000);
                    dispatch({
                        type: 'set_is_user_loading_image',
                        data: true,
                    });
                } else if (res?.data?.code === 9000) {
                    message.error('Your points are running low, please contact us promptly for assistance.');
                    setDisabledSubmit(false);
                    setSubmitError(true);
                } else if (res?.error?.code === 'ERR_NETWORK') {
                    message.error('Network Error!');
                    setSubmitError(true);
                    setError(res?.error)
                } else {
                    message.error('Please refresh and try again later!');
                    setSubmitError(true);
                    setDisabledSubmit(false);
                }
            } catch (error) {
                message.error('Please refresh and try again later!');
                setSubmitError(true);
                setDisabledSubmit(false);
            }
        } else {
            const currentUrl = `/app/StyleID`;
            sessionStorage.setItem('redirectUrl', currentUrl);
            if (tasks[6].isEver) {
                auth.signinRedirect();
            } else {
                dispatch({
                    type: 'set_login_intercept_modal',
                    data: {
                        isModal: true,
                        isEver: tasks[6].isEver,
                        currentUrl: currentUrl
                    }
                });
            }
        }
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const beforeUpload = async (file) => {
        if (auth?.isAuthenticated) {
            try {
                const isJpgOrPng = file.type.startsWith('image');
                if (!isJpgOrPng) {
                    message.error('You can only upload image files!');
                    // return Upload.LIST_IGNORE;
                    return false;
                }
                const isLt2M = file.size / 1024 / 1024 < 6;
                if (!isLt2M) {
                    message.error('The image must be less than 6MB!');
                    // return Upload.LIST_IGNORE;
                    return false;
                }
    
                return false;
            } catch (error) {
                message.error('Please refresh and try again later!');
                // return Upload.LIST_IGNORE;
                return false;
            }
        } else {
            const currentUrl = `/app/StyleID`;
            sessionStorage.setItem('redirectUrl', currentUrl);
            if (tasks[6].isEver) {
                auth.signinRedirect();
            } else {
                dispatch({
                    type: 'set_login_intercept_modal',
                    data: {
                        isModal: true,
                        isEver: tasks[6].isEver,
                        currentUrl: currentUrl
                    }
                });
            }
            return false;
        }
    };
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const openFileDialogOnClick = () => {
        if (auth?.isAuthenticated) {
            setAllowUpload(true);
        } else {
            const currentUrl = `/app/StyleID`;
            sessionStorage.setItem('redirectUrl', currentUrl);
            if (tasks[6].isEver) {
                auth.signinRedirect();
            } else {
                dispatch({
                    type: 'set_login_intercept_modal',
                    data: {
                        isModal: true,
                        isEver: tasks[6].isEver,
                        currentUrl: currentUrl
                    }
                });
            }
            setAllowUpload(false);
        }
    }
    const handleChange = ({ fileList: newFileList }) => {
        if (auth?.isAuthenticated) {
            setFileList(newFileList);
            try {
                const reader = new FileReader();
                reader.onload = function(event) {
                    const base64String = event.target.result;
                    updataIDB({ id: 'useStyleIDImage', image: base64String })
                };
                reader.readAsDataURL(newFileList[0].originFileObj);
            } catch (error) { /* empty */ }
        } else {
            const currentUrl = `/app/StyleID`;
            sessionStorage.setItem('redirectUrl', currentUrl);
            if (tasks[6].isEver) {
                auth.signinRedirect();
            } else {
                dispatch({
                    type: 'set_login_intercept_modal',
                    data: {
                        isModal: true,
                        isEver: tasks[6].isEver,
                        currentUrl: currentUrl
                    }
                });
            }
        }
    };

    const uploadButton = (
        <button type="button" onClick={openFileDialogOnClick}>
            <PlusOutlined />
            <div>Upload</div>
        </button>
    );

    const handleMouseEnter = () => {
        setIsInside(true);
    };
    const handleMouseLeave = () => {
        setIsInside(false);
    };
    const handleMouseMove = () => {
        if (isInside) {
            setIsMoving(true);
        }
    };

    const onVisibleChange = (visible) => {
        setPreviewOpen(visible);
    };

    const afterOpenChange = (visible) => {
        !visible && setPreviewImage('');
    }

    const onRemoveImage = () => {
        try {
            deletedataIDB('useStyleIDImage');
            setFileList([]);
            setPreviewImage('');
            setPreviewOpen(false);
        } catch (error) { /* empty */ }
    }

    useEffect(() => {
        setAllowUpload(auth?.isAuthenticated);
    }, [auth?.isAuthenticated]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [config]);

    useEffect(() => {
        if (type && name) {
            const fetchData = async() => {
                const playgroundData = await GetScenesInfo({ type, name });
                if (playgroundData?.data?.code === 1000) {
                    const getScenesList = playgroundData.data.data[0] || [];
                    setRenderData(getScenesList);
                    if (getScenesList.scenes.length > 0) {
                        const newList = JSON.parse(JSON.stringify(getScenesList.scenes));
                        const transformedArray = newList.reduce((acc, curr) => {
                            const existingType = acc.find(item => item.type === curr.type);
                            
                            const dataObj = { ...curr };
                            delete dataObj.type;
                        
                            if (existingType) {
                                existingType.data.push(dataObj);
                            } else {
                                acc.push({ type: curr.type, data: [dataObj] });
                            }
                        
                            return acc;
                        }, []);
                        for (let i = 0; i < transformedArray.length; i++) {
                            transformedArray[i].data = transformedArray[i].data.sort((a, b) => a.order - b.order)
                        }
                        transformedArray.sort((a, b) => a.type - b.type)
                        setCarouselData(transformedArray);
                        setTemplateImage(transformedArray[0].type);
                    }
                } else {
                    setRenderData({});
                    setError(playgroundData.error)
                }
            }
            fetchData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, type]);

    useEffect(() => {
        (async () => {
            const getUseStyleIDImage = await getdataIDB('useStyleIDImage');
            if (getUseStyleIDImage && auth?.isAuthenticated) {
                const base64String = getUseStyleIDImage.image;
                if (base64String) {
                    const blob = dataURLtoBlob(base64String);
                    const file = new File([blob], "useStyleIDImage.png", { type: 'image/png' });
                    let thumbUrl = await getBase64(file);
                    setFileList([{
                        name: file.name,
                        status: 'done',
                        url: URL.createObjectURL(file),
                        originFileObj: file,
                    }]);
                    if (form) {
                        form.setFieldsValue({
                            source: [{
                                name: file.name,
                                status: 'done',
                                url: URL.createObjectURL(file),
                                originFileObj: file,
                            }]
                        })
                    }
                    setPreviewImage(thumbUrl);
                }
            }
        })()
    }, [auth?.isAuthenticated, form]);
    useEffect(() => {
        return () => {
            if (styleIdInterval) {
                clearInterval(styleIdInterval.current);
            }
        }
    }, []);

    return (<div className="styleid-bg">
        <div className="styleid">
            <div className="styleid-header">
                <img src={homeicon} alt="" onClick={() => navigate(`/`)} />
                <div><RightOutlined/></div>
                <div onClick={() => navigate(`/app/StyleID`)}>{"StyleID"}</div>
                <div><RightOutlined /></div>
                {/* <div>{"Playground"}</div> */}
                <div>{renderData.category ? renderData.category.replace(/\s+/g, ' ').trim() : ""}</div>
            </div>
            <div className="styleid-box">
                <div className="prompt">
                    <Form
                        form={form}
                        name="validate_other"
                        onFinish={handleFormSubmit}
                        className='form'
                    >
                        <div className="upload">
                            <div className="title">
                                <span>{"Themes"}</span>
                                <span>{"Upload your photo"}</span>
                            </div>
                            <div className="image-show">
                                <div className="template">
                                    {
                                        carouselData.length > 0 ?
                                        <>
                                            <div className="template-image">
                                                <Carousel
                                                    autoplay={!disabledSubmit}
                                                    adaptiveHeight={true}
                                                    dots={false}
                                                    fade={true}
                                                    autoplaySpeed={1500}
                                                >
                                                    {
                                                        carouselData.find(item => item.type === templateImage).data ? carouselData.find(item => item.type === templateImage).data.map((item) => <div key={item.imageurl}>
                                                            <img
                                                                src={item.imageurl}
                                                                alt={item.imageurl}
                                                                className="template-item-image"
                                                            />
                                                        </div>) : <></>
                                                    }
                                                </Carousel>
                                            </div>
                                            <div className="exa-img">
                                                {
                                                    carouselData.length > 0 ? carouselData.map((item) =>
                                                        <div
                                                            onClick={disabledSubmit ? () => { } : () => handleFileChange(item.type)}
                                                            style={{ cursor: disabledSubmit ? "not-allowed" : "pointer", }}
                                                            key={item.type}
                                                        >
                                                            <img
                                                                src={item.data[0].imageurl}
                                                                alt={item.type}
                                                                style={{
                                                                    borderColor: templateImage === item.type ? 'rgba(255, 255, 255, 1)' : 'transparent',
                                                                }}
                                                            />
                                                        </div>
                                                    ) : <></>
                                                }
                                            </div>
                                        </> : <></>
                                    }
                                  
                                </div>
                                <div className="action">
                                    <Form.Item
                                        name="source"
                                        valuePropName="fileList"
                                        getValueFromEvent={normFile}
                                        rules={[{ required: true, message: 'Please select image' }]}
                                        className="upload-item"
                                    >
                                        <Upload
                                            action={null}
                                            listType="picture-card"
                                            fileList={fileList}
                                            onPreview={handlePreview}
                                            onChange={handleChange}
                                            name="source"
                                            maxCount={1}
                                            beforeUpload={beforeUpload}
                                            className='upload-source'
                                            disabled={disabledSubmit}
                                            openFileDialogOnClick={allowUpload}
                                            onRemove={onRemoveImage}
                                        >
                                            {fileList.length >= 1 ? null : uploadButton}
                                        </Upload>
                                    </Form.Item>
                                    {previewImage && (
                                        <Image
                                            wrapperStyle={{
                                                display: 'none',
                                            }}
                                            preview={{
                                                visible: previewOpen,
                                                onVisibleChange: onVisibleChange,
                                                afterOpenChange: afterOpenChange,
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                    {
                                        fileList.length < 1 ?
                                        <ExclamationCircleOutlined
                                            className="notice"
                                            onClick={() => setNoticeModalOpen(true)}
                                        /> : <></>
                                    }
                                    <Modal
                                        title={<div style={{ height: "clamp(6px, 4rem, 40px)" }}></div>}
                                        centered
                                        width={800}
                                        open={noticeModalOpen}
                                        onCancel={() => { setNoticeModalOpen(false) }}
                                        footer={null}
                                        className="notice-modal"
                                        // maskClosable={false}
                                    >
                                        <div className="notice-modal-content">
                                            <img src={notice_modal_image} alt="" />
                                            <div>
                                                <div className="title">{"Please upload a well-lit half-body photo with clear facial features"}</div>
                                                <div className="alter">{'Using the following image may not meet your expectations :'}</div>
                                                <ul className="text">
                                                    <li>Full-body photos</li>
                                                    <li>Photos containing multiple subjects</li>
                                                    <li>Black and white photos or low-quality images</li>
                                                    <li>Photos where the face is covered by masks sunglasses, hands, shadows, etc.</li>
                                                    <li>Photos with incomplete facial features, open mouth and show teeth</li>
                                                    <li>Overdone the beauty and make-up</li>
                                                    <li>Crooked headshot, incomplete head</li>
                                                    <li>NSFW or illegal photos</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                            <div className="footer">
                                <div className="portraits">
                                    <div>
                                        <img src={integral_icon} alt=""/>
                                        <div>{renderData.point || ""}</div>
                                    </div>
                                    <div>{ renderData.batchsize ? `Generate ${renderData.batchsize} artworks a time.` : ""}</div>
                                </div>
                                <Button
                                    type="primary"
                                    shape="round"
                                    size="large"
                                    className="but"
                                    onClick={handleSubmit}
                                    disabled={disabledSubmit}
                                >
                                    {'Generate'}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className="output-image" style={{ backgroundColor: getImageUrl.length < 1 ? 'rgba(222, 222, 222, 1)' : 'rgba(255, 255, 255, 1)' }}>
                    <div className="output-image-bfc" ref={myRef}>
                        {
                            getImageUrl.length > 0 ? <>
                                <div className="container">
                                    <div
                                        id="hover-area"
                                        className="main-image"
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                        onMouseMove={handleMouseMove}
                                    >
                                        <Image
                                            alt={getImageUrl[generateImagePreview]}
                                            src={getImageUrl[generateImagePreview]}
                                            preview={isMoving}
                                            placeholder={<div style={{ backgroundColor: 'white', width: '100%', height: '100%' }}><Skeleton.Image active={true} className="main-image"/></div>}
                                        />
                                    </div>
                                    {
                                        getImageUrl.length > 0 ?
                                        <div className="thumbnail-container">
                                            {
                                                getImageUrl.length > 0 ?
                                                getImageUrl.map((item, index) => <div className="thumbnail" key={item}>
                                                    <div
                                                        onClick={() => setGenerateImagePreview(index)}
                                                        style={{
                                                            border: `2px solid ${generateImagePreview === index ? "rgba(25, 73, 255, 1)" : "rgba(25, 73, 255, 0)"}`
                                                        }}
                                                    >
                                                        <Image
                                                            alt={item}
                                                            src={item}
                                                            preview={false}
                                                            placeholder={<div style={{ backgroundColor: 'white', width: '100%', height: '100%' }}><Skeleton.Image active={true} className="thumbnail"/></div>}
                                                        />
                                                    </div>
                                                </div> ) : <></>
                                            }
                                        </div> : <></>
                                    }
                                </div>
                            </> : initialization ?
                            <div className="noimage-box">
                                <img className="noimage" src={noimage} alt="" />
                            </div> : <></>
                        }
                        {
                            disabledSubmit && !submitError ?
                            <div className="loading-box">
                                <img className="noimage" src={noimage} alt="" />
                            </div> : <></>
                        }
                        {
                            submitError && !disabledSubmit ?
                                <div className="error-box">
                                    <img src={action_loading_error} alt="" />
                                    <div>{'Image loading failure'}</div>
                                </div> : <></>
                        }
                    </div>
                </div>
            </div>
            {/* <div className="styleid-footer" style={{ marginTop: window.innerWidth > 768 ? (getImageUrl.length > 0 ? "15.7rem" : "5.7rem") : (getImageUrl.length > 0 ? '92px' : '30px') }}>
                <div className="footer-title">GoodAPP</div>
                <div className="footer-content">{'我们专注AI模型开发和优化部署，致力于成为AIGC视觉方案中的领军者，通过创新和可靠的解决方案，与合作伙伴一起打造视觉上的无限可能。'}</div>
            </div>
            <div className="styleid-image-list">
                <img src={template4} alt="" />
                <img src={template5} alt="" />
                <img src={template6} alt="" />
                <img src={template7} alt="" />
            </div> */}
        </div>
    </div>)
};
export default StyleID;