import React, { useState } from "react";
import './index.css';
import {
    Modal,
    Button,
    Checkbox
} from 'antd';
import { CarryOutOutlined } from '@ant-design/icons';
import { useAuth } from 'react-oidc-context';
import { useTasks, useTasksDispatch } from '../../TasksContext';


const LoginInterceptModal = () => {
    const auth = useAuth();
    const tasks = useTasks();
    const dispatch = useTasksDispatch();
    const [checkedValue, setCheckedValue] = useState(false);
    const handleOk = () => {
        try {
            sessionStorage.setItem('redirectUrl', tasks[6].currentUrl);
            auth.signinRedirect();
            dispatch({
                type: 'set_login_intercept_modal',
                data: {
                    isModal: true,
                    isEver: checkedValue,
                    currentUrl: tasks[6].currentUrl
                }
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
        }
    };
    const handleCancel = () => {
        dispatch({
            type: 'set_login_intercept_modal',
            data: {
                isModal: false,
                isEver: tasks[6].isEver,
                currentUrl: tasks[6].currentUrl
            }
        });
    };
    const onChange = (e) => {
        setCheckedValue(e.target.checked);
    };

    return (
        <Modal
            title={<div>
                <CarryOutOutlined />
                <div>{'Register Award'}</div>
            </div>}
            width={448}
            open={tasks[6].isModal && !tasks[6].isEver}
            onOk={handleOk}
            onCancel={handleCancel}
            centered
            maskClosable={false}
            footer={[
                <Button key="ok" type="primary" onClick={handleOk}>
                    {"Log In Now"}
                </Button>
            ]}
            className='login-intercept-modal'
        >
            <div>{"Get 5,000 Free Points for Registration!"}</div>
            <div>{"Join us in November and take advantage of our exclusive offer for new users. Start your experience right now!"}</div>
            <div>
                <Checkbox onChange={onChange}>{'Not display in future'}</Checkbox>
            </div>
        </Modal>
    )
}

export default LoginInterceptModal;